import React from 'react'

export const BuyAnApe = () => {
  return (
    <div className='fair-distribution-section' id='ape'>
        <div className="max-w-[1132px] m-auto px-4 pt-12 mb-6">
            
        </div>
    </div>
  )
}
