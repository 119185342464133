import React from 'react';
import ReactDOM from 'react-dom/client';
import '../src/assets/css/index.css';
import {  BrowserRouter  } from "react-router-dom";
import App from './App';
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <BrowserRouter>
        <div className='bg-black'>
            <App />
        </div>
    </BrowserRouter>
  </React.StrictMode>
);