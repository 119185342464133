import React from 'react';
import Toilet from '../assets/images/toilet.png'

export const BathRoom = () => {
  return (
    <div className='bathRoom-section'>
        <div className="max-w-[1132px] m-auto px-4 pt-12 mt-12 border-t border-solid border-Border">
            <div className="row flex flex-wrap">
                <div className="left w-full md:w-[80%] md:pr-20">
                    <h2 className='mb-4 text-2xl md:text-3xl text-white font-extrabold italic'>Membership</h2>
                    <p className='mb-4 text-sm md:text-base text-justify text-white'>Lorem ipsum dolor sit amet consectetur, adipisicing elit. Molestiae libero alias aut ipsam rerum, temporibus fuga, itaque eum ab optio doloribus officiis. Itaque sequi architecto voluptas est! Error, sed aspernatur?</p>
                    <p className='mb-4 text-sm md:text-base text-justify text-white'>Lorem ipsum dolor sit, amet consectetur adipisicing elit. Distinctio mollitia labore odit recusandae hic voluptatibus alias explicabo autem deserunt fugit. Quas porro dolor facere vel. Nulla doloribus assumenda voluptatibus provident!</p>
                </div>
                
            </div>
        </div>
    </div>
  )
}
