import React from 'react';
import Ape1 from '../assets/images/1.jpeg'
import Ape2 from '../assets/images/2.jpeg'
import Ape3 from '../assets/images/3.jpeg'
import Ape4 from '../assets/images/4.jpeg'

export const YachtClub = () => {
  return (
    <div className='yachtclub-section'>
        <div className="max-w-[1132px] m-auto px-4 pt-12 mt-12 border-t border-solid border-Border mb-6">
            <div className="row flex flex-wrap">
                <div className="left w-full md:w-[70%] md:pr-20">
                    <h2 className='mb-4 text-2xl md:text-3xl text-white font-extrabold italic'>Welcome to Hanako NFT Collection.</h2>
                    <p className='mb-4 text-sm md:text-base text-justify text-white'>Lorem ipsum dolor sit amet consectetur, adipisicing elit. Est commodi ratione culpa accusantium veritatis? Earum temporibus, nesciunt porro consequuntur fugit vel velit voluptatum. Dicta quibusdam vero ipsa aperiam eius fuga.</p>
                    <p className='mb-4 text-sm md:text-base text-justify text-white'></p>
                    <p className='mb-4 text-sm md:text-base text-justify text-white'></p>
                </div>
                <div className="right w-full md:w-[30%] grid grid-cols-2 gap-5 mt-5 md:my-0">
                    <div className="image"><img src={Ape1} alt="Team1" /></div>
                    <div className="image"><img src={Ape2} alt="Team2" /></div>
                    <div className="image"><img src={Ape3} alt="Team3" /></div>
                    <div className="image"><img src={Ape4} alt="Team4" /></div>
                </div>
            </div>
        </div>
    </div>
  )
}
