import React from 'react';

export const Specs = () => {
  return (
    <div className='team-section'>
        <div className="max-w-[1132px] m-auto px-4 pt-12 mt-12 border-t border-solid border-Border">
            <div className="row flex flex-wrap">
                <div className="left w-full md:w-[70%] md:pr-20">
                    <h2 className='mb-4 text-2xl md:text-3xl text-white font-extrabold italic'>The Art and the Money!</h2>
                    <p className='mb-4 text-sm md:text-base text-justify text-white'>Lorem ipsum. </p>
                </div>
                
            </div>
        </div>
    </div>
  )
}
