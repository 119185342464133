import React from 'react';
import Team1 from '../assets/images/1.jpeg'
import Team2 from '../assets/images/2.jpeg'
import Team3 from '../assets/images/3.jpeg'
import Team4 from '../assets/images/4.jpeg'

export const Team = () => {
  return (
    <div className='team-section' id='team'>
        <div className="max-w-[1132px] m-auto px-4 pt-12 mt-12 border-t border-solid border-Border">
            <div className="row flex flex-wrap">
                <div className="left w-full md:w-[70%] md:pr-20">
                    <h2 className='mb-4 text-2xl md:text-3xl text-white font-extrabold italic'>THE TEAM</h2>
                    <p className='mb-4 text-sm md:text-base text-justify text-white'> The team consists of high-quality builders in web3 and are listed below:</p>
                    <p className='mb-4 text-sm md:text-base text-justify text-white'>
                        <span className='text-Brand'>Mikuru: </span>
                        <span className='italic'>Founder</span>
                    </p>
                    <p className='mb-4 text-sm md:text-base text-justify text-white'>
                        <span className='text-Brand'>SMOL: </span>
                        <span className='italic'>Artist</span>
                    </p>
                    <p className='mb-4 text-sm md:text-base text-justify text-white'>
                        <span className='text-Brand'>[K]: </span>
                        <span className='italic'>Developer</span>
                    </p>
                    <p className='mb-4 text-sm md:text-base text-justify text-white'>
                        <span className='text-Brand'>Nefertiti: </span>
                        <span className='italic'>Community advisor</span>
                    </p>
                    <p className='mb-4 text-sm md:text-base text-justify text-white'>
                        <span className='text-Brand'>Vallent: </span>
                        <span className='italic'>Community advisor</span>
                    </p>
            
                    {/* <p className='mb-4 text-sm md:text-base text-justify text-white'>
                        <span className='text-Brand'>NO SASS. </span>
                        <span className='italic'>HERE FOR THE APES. NOT FOR THE SASS.</span>
                    </p> */}
                </div>
                <div className="right w-full md:w-[30%] grid grid-cols-2 gap-5">
                    <div className="image"><img src={Team1} alt="Team1" /></div>
                    <div className="image"><img src={Team2} alt="Team2" /></div>
                    <div className="image"><img src={Team3} alt="Team3" /></div>
                    <div className="image"><img src={Team4} alt="Team4" /></div>
                </div>
            </div>
            <div className="row py-12">
                
            </div>
        </div>
    </div>
  )
}
